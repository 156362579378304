body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff6d6;
  background-image: url('grid-bg.png');
  color: #42210B;
}

.container{
  padding-top:10px;
  max-width: 600px;
}


.logo-menu-top{
  margin: 0 auto;
  padding-bottom:10px;
   width: 100%;
   z-index: 50 !important;
   background-color: #fff6d6;
  background-image: url('grid-bg.png');
   position: sticky;
  top: 0;
 }
 .logo-home{
   width:70%;
   margin-bottom:25px;
 }
 .donutPlaceList{
   width: 97%;
   margin: 0 auto;
 }
 .card-title{
   font-weight: 700;
   font-size: 1.3em;
 }

.pink-glace{
  background-color: #FF9EAC;
  color: #42210B;
}
.dough{
  background-color: #D2AE86;
}

.donut-list-item{
  margin-bottom:10px;
}

input {
  border: 0 !important;
  margin:0 !important;
  padding: 5px !important;
}
input:focus {
  border-bottom: none!important;
  box-shadow: none!important;
}

a{
  color: #42210B;


}
.tinyText{
  font-size: 0.8em;
   width: 100%;
   text-align: center;
   margin-top:10px;
}

.link_active{
  text-decoration:underline;
  font-weight: 700;
  font-size: 1.2em;
}

.donut-card{
  border-radius: 5px 50px 0px 0px !important;
  padding: 10px;
}
.donut-card-bottom{
  border-radius: 0px 0px 20px 20px !important;
  padding: 10px;
}
.donut-card-middle{
  padding: 7px 10px;
}

.menu-horizontal{
  padding-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 0px;
  margin-bottom: 0px;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  box-pack: justify ;
  display: flex ;
  flex-wrap: no-wrap;
  justify-content: space-between;
  align-items: center ;    
}

.menu-horizontal .btn{

  border-radius: 5px 25px 15px !important;
}

.btn-home{

  border-radius: 10px 50px 30px !important;
}


/* /////////////animation start//////////// */
#sig1{
  opacity:0;
  animation-name: signal1;
  animation-duration:1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
#sig2{
  opacity:0;
  animation-name: signal2;
  animation-duration:1s;
  animation-delay: 0.3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
#sig3{
  opacity:0;
  animation-name: signal3;
  animation-duration:1s;
  animation-delay: 0.6s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes signal1 {
  0% { opacity: 0; }
  25% { opacity: 0; }
  50% { opacity: 1; }
  75% { opacity: 0; }
  100% { opacity: 0; }
}
@keyframes signal2 {
  0% { opacity: 0; }
  25% { opacity: 0; }
  50% { opacity: 1; }
  75% { opacity: 0; }
  100% { opacity: 0; }
}
@keyframes signal3 {
  0% { opacity: 0; }
  25% { opacity: 0; }
  50% { opacity: 1; }
  75% { opacity: 0; }
  100% { opacity: 0; }
}

/* /////////////animation end//////////// */


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
